import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Formik } from 'formik';
// import rbiiIcon from "../images/rbiIcon.png";
// import { useState } from "react";

import { navigateTo } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


function ContactPage() {
  const [isSent, send] = useState(false); // has sent contact form already
  const [formData, setData] = useState({ "name": "", "email": "", "message": "" });
  const [errorMessage, setError] = useState("");

  function successfulSubmission(a) {
    send(true);
    setTimeout(() => {
      navigateTo(a);
      // window.open("/", "_self");
    }, 750);
  }

  function handleSubmission(e) {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData
      })
    })
      .then(() => successfulSubmission(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={[`RBII`, `software`, `contact`, `reply`, `quote`, `tech`, `restaurant`, `email`]}
      />
      <section>

        {/* data-netlify="true" */}
        {/* in form */}


        <Formik
          initialValues={{ bot: '', name: '', email: '', message: '' }}
          validate={values => {
            let errors = {};
            if (!values.email) {
              // errors.email = 'Required';
              //setError("Please provide an email.");
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              const emailInvalid = 'The email address is not valid.';
              // errors.email = emailInvalid;
              //setError(emailInvalid);
            } else if (!values.message) {

            } else {
              setError("");
            }
            setData({ "bot-field": values.bot, "name": values.name, "email": values.email, "message": values.message });
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              setError("Please provide a valid email.");
              return;
            }

            if (!values.message) {
              // errors.email = 'Required';
              setError("What do you want to develop?");
              return;
            }

            send(true);
            //send to forms
            setTimeout(() => {
              window.open("/", "_self");
              setSubmitting(false);
            }, 1000);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (

              // handleSubmit
              // handleSubmission
              <form name="contact" className="mx-auto md:w-1/2" method="post" onSubmit={handleSubmission} data-netlify="true" data-netlify-honeypot="bot-field" >
                <p className="leading-loose text-2xl font-bold mb-4">
                  {errorMessage == "" ? isSent ? "Thank you." : "We'll work promptly." : errorMessage}
                  {/* {isSent ? "Thank you, someone will reach out to you shortly." : "We'll work promptly."} */}
                </p>

                <label
                  className="block font-bold mb-2 text-xs uppercase"
                  htmlFor="name"
                >
                  Name
                </label>

                <input type="hidden" name="form-name" value="contact" />

                <p hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bot}
                    />
                  </label>
                </p>

                <input
                  className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                  id="name"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  // onChange={(e) => setName(e.target.value)}
                  placeholder="Jon"
                />

                <label
                  className="block font-bold mb-2 text-xs uppercase"
                  htmlFor="email"
                >
                  Email
                </label>

                <input
                  className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                  id="email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  // onChange={(e) => setName(e.target.value)}
                  placeholder="jondoe@gmail.com"
                />

                <label
                  className="block font-bold mb-2 text-xs uppercase"
                  htmlFor="message"
                >
                  Message
                </label>

                <textarea
                  className="appearance-none bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                  id="message"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  // onChange={(e) => setBody(e.target.value)}
                  placeholder="How can we help?"
                  rows="8"
                />

                <button
                  // onClick={() => window.open("mailto:" + "jnblanchard@mac.com" + '?&subject=' + "Greetings from RBII" + '&body=' + composeBody(), '_self')}
                  type="submit"
                  className="border-b-4 border-yellow-700 hover:border-yellow-700 bg-yellow-600 hover:bg-yellow-500 font-bold px-4 py-2 rounded text-sm text-white">
                  Submit
                </button>
              </form>


            )}
        </Formik>

      </section>
    </Layout>
  );
}

export default ContactPage;
